import React, { useRef, useEffect, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import logo from "../../images/logo.png";

// import data from "./data";

import { Link } from "gatsby";
import "./style.scss";

import { useQueryParam, StringParam } from "use-query-params";
import SEO from "../seo";

export default function FlipBook({ location }) {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [num, setNum] = useQueryParam("page", StringParam);

  const [currentPage, setCurrentPage] = useState(Number(num) || 0);

  // const [reset, setReset] = useState(false);

  // const [dimension, setDimension] = useState({ h: 733, w: 570 });
  const dimension = { h: 733, w: 570 };

  const [bubbleValue, setBubbleValue] = useState(Number(num) || 0);

  function onClickNext() {
    let isMobile = window.matchMedia("(max-width: 756px)").matches;

    if (!isMobile) {
      ref.current.pageFlip().flipNext();
    } else {
      ref2.current.pageFlip().flipNext();
    }
    setCurrentPage(currentPage + 1 < 152 ? currentPage + 1 : 151);
  }
  function onClickPrev() {
    let isMobile = window.matchMedia("(max-width: 756px)").matches;
    if (!isMobile) {
      ref.current.pageFlip().flipPrev();
    } else {
      ref2.current.pageFlip().flipPrev();
    }
    setCurrentPage(currentPage - 1 > 0 ? currentPage - 1 : 0);
  }

  function onPageFlip(num) {
    setCurrentPage(num.data);
  }

  useEffect(() => {
    setNum(currentPage);
    const slider = document.querySelector(`input[type="range"]`);
    slider.value = currentPage;
  }, [currentPage, setNum]);

  function flipToPage(index) {
    // setNum(index);
    ref.current.pageFlip().flip(Number(index));
    ref2.current.pageFlip().flip(Number(index));
  }

  function handleOnSliderChange(e) {
    setBubbleValue(e.target.value);
    document.querySelector(".bubble").style.left = `${
      (e.target.value / 157) * 100
    }%`;
  }

  useEffect(() => {
    setTimeout(() => {
      ref.current.pageFlip().flip(currentPage);
      ref2.current.pageFlip().flip(currentPage);
    }, 100);

    const slider = document.querySelector(`input[type="range"]`);
    // const sliderContainer = document.querySelector(`.flipbook__slider`);
    const bubble = document.querySelector(".bubble");

    slider.addEventListener("change", (e) => {
      flipToPage(e.target.value);
      console.log("changing page", e.target.value);
    });

    slider.addEventListener("mousedown", (e) => {
      bubble.style.opacity = 1;
    });

    slider.addEventListener("mouseup", (e) => {
      bubble.style.opacity = 0;
    });

    slider.addEventListener("touchstart", (e) => {
      bubble.style.opacity = 1;
    });

    slider.addEventListener("touchend", (e) => {
      bubble.style.opacity = 0;
    });

    slider.value = Number(num);

    let h = window.innerHeight;
    const flipbook = document.querySelector(".flipbook");

    let isMobile = window.matchMedia("(max-width: 756px)").matches;

    slider.value = currentPage;

    if (isMobile) {
      flipbook.setAttribute("style", `height:${h}px;`);
    } else {
      flipbook.setAttribute("style", `min-height: 100vh;`);
    }

    window.addEventListener("resize", () => {
      let h = window.innerHeight;

      let isMobile = window.matchMedia("(max-width: 756px)").matches;
      if (isMobile) {
        flipbook.setAttribute("style", `height:${h}px;`);
      } else {
        flipbook.setAttribute("style", `min-height: 100vh;`);
      }
    });
  }, []);

  // useEffect(() => {
  //   setReset(false);
  // }, [JSON.stringify(dimension)]);

  return (
    <div className="flipbook">
      <SEO
        title="Abhyudya | Annual E-Magazine | Chinmaya Vidyalaya Vasant Vihar"
        description="View the school’s annual roundup for 2020-2021 and explore messages, art, pictures and articles in an interactive layout."
        img="https://chinmayavvdelhi.ac.in/assets/images/abhyudya-link-preview.jpg"
      />
      <a
        className="flipbook__logo__container"
        href="https://chinmayavvdelhi.ac.in/"
      >
        <img alt="" className="flipbook__logo" src={logo} />

        <div className="flipbook__logo__text">
          Chinmaya Vidyalaya
          <br />
          Vasant Vihar, Delhi
        </div>
      </a>

      <div className="flipbook__backtowebsite">
        <div className="socialshare">
          Share&nbsp;
          <span className="hicon">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=http://abhyudya.chinmayavvdelhi.ac.in/`}
              target="_blank"
              className=""
            >
              <i class="fab fa-facebook-f"></i>
            </a>
          </span>
          <span className="hicon">
            <a
              href={`https://twitter.com/intent/tweet?url=http://abhyudya.chinmayavvdelhi.ac.in/`}
              target="_blank"
              className=""
            >
              <i class="fab fa-twitter"></i>
            </a>
          </span>
          <span className="hicon">
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=http://abhyudya.chinmayavvdelhi.ac.in/`}
              target="_blank"
              className=""
            >
              <i class="fab fa-linkedin-in"></i>
            </a>
          </span>
        </div>
        <div>
          <a href="https://chinmayavvdelhi.ac.in/">
            <i class="fas fa-arrow-left"></i>
            &nbsp; Back To Home
          </a>
        </div>
      </div>

      <div className="flipbook__slider">
        <div className="bubble">{bubbleValue}</div>
        <input
          onChange={handleOnSliderChange}
          type="range"
          min={0}
          defaultValue={num || 0}
          max={151}
        ></input>
      </div>
      <div className="flipbook__container desktop">
        <HTMLFlipBook
          width={dimension.w}
          height={dimension.h}
          // size="stretch"
          ref={ref}
          minWidth={315}
          maxWidth={570}
          minHeight={400}
          maxHeight={1533}
          maxShadowOpacity={0.5}
          flippingTime={800}
          // showCover={true}
          autoSize={true}
          mobileScrollSupport={true}
          onFlip={onPageFlip}
          // onChangeOrientation={this.onChangeOrientation}
          // onChangeState={this.onChangeState}
          renderOnlyPageLengthChange={true}
          useMouseEvents={false}
          className="demo-book"
          drawShadow={true}
        >
          {pagesdata}
        </HTMLFlipBook>
      </div>

      <div className="flipbook__container mobile">
        <HTMLFlipBook
          width={350}
          height={450}
          // size="stretch"
          ref={ref2}
          minWidth={350}
          maxWidth={450}
          minHeight={400}
          useMouseEvents={false}
          maxHeight={1533}
          maxShadowOpacity={0.5}
          flippingTime={800}
          // showCover={true}
          autoSize={true}
          onFlip={onPageFlip}
          mobileScrollSupport={true}
          // onFlip={this.onPage}
          // onChangeOrientation={this.onChangeOrientation}
          // onChangeState={this.onChangeState}
          className="demo-book"
          drawShadow={true}
        >
          {pagesdata}
        </HTMLFlipBook>
      </div>

      <button
        className="flipbook__btn flipbook__btn--prev"
        onClick={onClickPrev}
      >
        <i class="fas fa-backward"></i>
      </button>
      <button
        className="flipbook__btn flipbook__btn--next"
        onClick={onClickNext}
      >
        <i class="fas fa-forward"></i>
      </button>
    </div>
  );
}

const pagesdata = [
  // <div className="page">
  //   <div className="page__container">
  //     <img alt="Magazine Page" src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/1.jpg"></img>
  //   </div>
  // </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/1.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/2.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/3.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/4.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/5.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/6.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/7.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/8.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/9.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/10.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/11.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/12.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/13.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/14.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/15.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/16.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/17.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/18.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/19.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/20.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/21.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/22.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/23.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/24.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/25.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/26.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/27.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/28.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/29.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/30.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/31.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/32.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/33.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/34.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/35.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/36.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/37.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/38.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/39.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/40.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/41.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/42.jpg"
      ></img>
    </div>
  </div>,

  <div className="page page--42">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/43.jpg"
      ></img>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://drive.google.com/file/d/1584WRlMJnNcn21M57a_1Bg3ZvgtAEg5Y/view?usp=sharing"
        className="page--42__button1"
      >
        Click here to explore
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://drive.google.com/file/d/12NmJl64Sxu5Lo7TLcluTqdOArg6lzGfc/view?usp=sharing"
        className="page--42__button2"
      >
        Apps by the Chinmaya Innovators
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://drive.google.com/file/d/12FRmn9-Hzx3V_50vm4O2RkXgf1capr8y/view?usp=sharing"
        className="page--42__button3"
      >
        Games by the Chinmaya Innovators
      </a>
    </div>
  </div>,

  <div className="page page--43">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/44.jpg"
      ></img>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://drive.google.com/file/d/16m-MJcUJp1Udf6rH36CB_XHIKhenXSy3/view?usp=sharing"
        className="page--43__button"
      >
        Watch Video
      </a>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/45.jpg"
      ></img>
    </div>
  </div>,

  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/46.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/47.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/48.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/49.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/50.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/51.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/52.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/53.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/54.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/55.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/56.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/57.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/58.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/59.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/60.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/61.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/62.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/63.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/64.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/65.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/66.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/67.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/68.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/69.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/70.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/71.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/72.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/73.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/74.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/75.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/76.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/77.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/78.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/79.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/80.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/81.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/82.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/83.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/84.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/85.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/86.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/87.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/88.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/89.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/90.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/91.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/92.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/93.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/94.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/95.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/96.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/97.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/98.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/99.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/100.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/101.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/102.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/103.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/104.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/105.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/106.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/107.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/108.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/109.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/110.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/111.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/112.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/113.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/114.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/115.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/116.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/117.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/118.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/119.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/120.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/121.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/122.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/123.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/124.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/125.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/126.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/127.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/128.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/129.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/130.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/131.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/132.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/133.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/134.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/135.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/136.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/137.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/138.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/139.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/140.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/141.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/142.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/143.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/144.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/145.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/146.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/147.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/148.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/149.jpg"
      ></img>
    </div>
  </div>,
  <div className="page page--154">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/150.jpg"
      ></img>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://youtu.be/m9lOITXZqng"
        className="page--154__button"
      >
        Watch Video
      </a>
    </div>
  </div>,

  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/151.jpg"
      ></img>
    </div>
  </div>,
  <div className="page">
    <div className="page__container">
      <img
        alt="Magazine Page"
        src="https://chinmayavvdelhi.ac.in/assets/images/magazine/compressed/152.jpg"
      ></img>
    </div>
  </div>,
];
